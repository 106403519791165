.form-control.is-invalid.remove_invalid {
  background-image: none;
}
.form-control.is-valid.remove_invalid {
  background-image: none;
}
.mt-25 {
  top: 25px !important;
}
.cp {
  cursor: pointer !important;
}
.max-h-in {
  max-height: inherit !important;
}
// li a:hover {
//   color: white !important;
// }
.page-item.active .page-link {
  color: white !important;
}
.css-1okebmr-indicatorSeparator {
  display: none !important;
}
.css-tj5bde-Svg {
  color: #5e6278 !important;
}
.css-l6isez-control {
  height: 42px !important;
  background-color: #f5f8fa !important;
  border: none !important;
}

//file upload
.dropzone {
  // cursor: pointer;
}
.dropzone {
  min-height: auto;
  padding: 1.5rem 1.75rem;
  text-align: center;
  // cursor: pointer;
  border: 1px dashed #009ef7;
  background-color: #f1faff;
  border-radius: 0.475rem !important;
}
.dropzone .dz-message {
  margin: 0;
  display: flex;
  text-align: left;
}
.dropzone .dz-preview {
  border-radius: 0.475rem !important;
  margin: 0.75rem;
}
.dropzone .dz-preview {
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin: 16px;
  min-height: 100px;
}

.dropzone .dz-preview.dz-file-preview .dz-image {
  background: #eff2f5;
}
.dropzone .dz-preview.dz-file-preview .dz-image {
  border-radius: 20px;
  background: #ebebeb;
  // background: linear-gradient(to bottom, #eee, #ddd);
}
.dropzone .dz-preview .dz-image {
  border-radius: 0.475rem !important;
  z-index: 1;
}
.dropzone .dz-preview {
  cursor: default;
}
.dropzone .dz-preview .dz-image {
  border-radius: 20px;
  overflow: hidden;
  width: 120px;
  height: 120px;
  position: relative;
  display: block;
  z-index: 10;
}
.dropzone .dz-preview.dz-file-preview .dz-details {
  opacity: 1;
}
.dropzone .dz-preview .dz-details {
  z-index: 20;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  font-size: 13px;
  min-width: 100%;
  max-width: 100%;
  padding: 2em 1em;
  text-align: center;
  color: rgba(0, 0, 0, 0.9);
  line-height: 150%;
  white-space: pre;
  margin-top: 18%;
}
.dropzone .dz-preview.dz-complete .dz-progress {
  opacity: 0;
  -webkit-transition: opacity 0.4s ease-in;
  -moz-transition: opacity 0.4s ease-in;
  -ms-transition: opacity 0.4s ease-in;
  -o-transition: opacity 0.4s ease-in;
  transition: opacity 0.4s ease-in;
}
.dropzone .dz-preview.dz-processing .dz-progress {
  opacity: 1;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.dropzone .dz-preview.dz-complete .dz-progress {
  opacity: 0;
  -webkit-transition: opacity 0.4s ease-in;
  -moz-transition: opacity 0.4s ease-in;
  -ms-transition: opacity 0.4s ease-in;
  -o-transition: opacity 0.4s ease-in;
  transition: opacity 0.4s ease-in;
}
.dropzone .dz-preview.dz-processing .dz-progress {
  opacity: 1;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.dropzone .dz-preview .dz-progress {
  opacity: 1;
  z-index: 1000;
  pointer-events: none;
  position: absolute;
  // height: 10px;
  right: 4%;
  bottom: 3%;
  margin-top: -8px;
  // width: 35px;
  margin-left: -40px;
  // background: rgba(255, 255, 255, 0.9);
  border-radius: 8px;
  // overflow: hidden;
}
.dropzone .dz-preview.dz-error .dz-error-message {
  display: block;
}
.dropzone .dz-preview .dz-error-message {
  pointer-events: none;
  z-index: 1000;
  position: absolute;
  display: block;
  display: none;
  opacity: 0;
  -webkit-transition: opacity 0.3s ease;
  -moz-transition: opacity 0.3s ease;
  -ms-transition: opacity 0.3s ease;
  -o-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
  border-radius: 8px;
  font-size: 13px;
  top: 130px;
  left: -10px;
  width: 140px;
  background: #be2626;
  background: linear-gradient(to bottom, #be2626, #a92222);
  padding: 0.5em 1.2em;
  color: white;
}
.dropzone .dz-error-message {
  color: #ffffff;
  background: #f1416c;
}
.dropzone .dz-success-mark,
.dropzone .dz-error-mark {
  margin-left: -20px !important;
  margin-top: -20px !important;
}
.dropzone .dz-preview.dz-error .dz-error-mark {
  opacity: 1;
  -webkit-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
  -moz-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
  -ms-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
  -o-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
  animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
}
.dropzone .dz-preview .dz-success-mark,
.dropzone .dz-preview .dz-error-mark {
  pointer-events: none;
  opacity: 1;
  z-index: 10;
  position: absolute;
  display: block;
  bottom: 4%;
  right: 3%;
  margin-left: -27px;
  margin-top: -27px;
}
.dropzone .dz-preview .dz-remove {
  font-size: 14px;
  text-align: center;
  display: block;
  cursor: pointer;
  border: none;
}
.dropzone .dz-remove {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.65rem;
  width: 1.65rem;
  font-size: 1rem;
  text-indent: -9999px;
  white-space: nowrap;
  position: absolute;
  z-index: 2;
  background-size: 40%;
  background-color: #ffffff !important;
  box-shadow: 0 0.1rem 1rem 0.25rem rgb(0 0 0 / 5%);
  border-radius: 100%;
  top: -0.825rem;
  right: -0.825rem;
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  // background-image: url();
}

.dropzone .dz-preview .dz-details .dz-filename {
  white-space: "nowrap" !important;
}
.dropzone .dz-preview .dz-details .dz-filename:not(:hover) {
  overflow: hidden;
  text-overflow: ellipsis;
}
.dropzone .dz-preview .dz-details .dz-filename:not(:hover) span {
  border: 1px solid transparent;
}
.dropzone .dz-preview .dz-details .dz-filename span,
.dropzone .dz-preview .dz-details .dz-size span {
  background-color: rgba(255, 255, 255, 0.4);
  padding: 0 0.4em;
  border-radius: 3px;
}
.dropzone .dz-preview .dz-details .dz-size {
  margin-bottom: 1em;
  font-size: 16px;
}
//end file upload

//view doc style
.view_dropzone {
  min-height: auto;
  padding: 1.5rem 1.75rem;
  text-align: center;
  // cursor: pointer;
  border: 1px dashed #009ef7;
  background-color: #f1faff;
  border-radius: 0.475rem !important;
}

.view_dropzone .dz-preview {
  border-radius: 0.475rem !important;
  margin: 0.75rem;
}
.view_dropzone .dz-preview {
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin: 16px;
  min-height: 100px;
}
.view_dropzone .dz-preview.dz-file-preview .dz-image {
  background: #eff2f5;
}
.view_dropzone .dz-preview.dz-file-preview .dz-image {
  border-radius: 20px;
  background: #ebebeb;
}
.view_dropzone .dz-preview .dz-image {
  border-radius: 0.475rem !important;
  z-index: 1;
}
.view_dropzone .dz-preview {
  // cursor: default;
}
.view_dropzone .dz-preview .dz-image {
  border-radius: 20px;
  overflow: hidden;
  width: 120px;
  height: 120px;
  position: relative;
  display: block;
  z-index: 10;
}

.view_dropzone .dz-preview.dz-file-preview .dz-details {
  opacity: 1;
}
.view_dropzone .dz-preview .dz-details {
  z-index: 20;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  font-size: 13px;
  min-width: 100%;
  max-width: 100%;
  padding: 2em 1em;
  text-align: center;
  color: rgba(0, 0, 0, 0.9);
  line-height: 150%;
  white-space: pre;
  margin-top: 18%;
}
.view_dropzone .dz-preview .dz-details .dz-filename {
  white-space: "nowrap" !important;
}
.view_dropzone .dz-preview .dz-details .dz-filename:not(:hover) {
  overflow: hidden;
  text-overflow: ellipsis;
}
.view_dropzone .dz-preview .dz-details .dz-filename:not(:hover) span {
  border: 1px solid transparent;
}
.view_dropzone .dz-preview .dz-details .dz-filename span,
.view_dropzone .dz-preview .dz-details .dz-size span {
  background-color: rgba(255, 255, 255, 0.4);
  padding: 0 0.4em;
  border-radius: 3px;
}
.view_dropzone .dz-success-mark {
  margin-left: -20px !important;
  margin-top: -20px !important;
}
.view_dropzone .dz-preview .dz-success-mark {
  // pointer-events: none;
  opacity: 1;
  z-index: 10;
  position: absolute;
  display: block;
  bottom: 4%;
  right: 3%;
  margin-left: -27px;
  margin-top: -27px;
}

.css-26l3qy-menu {
  z-index: 9 !important;
  position: relative;
}
#picker-container .flatpickr-calendar {
  top: 1000px !important;
  left: 1000px !important;
}
.react-date-picker__inputGroup__input {
  // display: none !important;
}
.react-date-picker__wrapper {
  border: none !important;
}
.react-date-picker__calendar-button react-date-picker__button {
  color: #a1a5b7 !important;
}
.react-date-picker__clear-button__icon react-date-picker__button__icon {
  color: #a1a5b7 !important;
}
.payment-box {
  width: 160px;
  height: 170px;
  border-radius: 5px;
  padding: 5%;
}
.p-null {
  background-color: #dcdcdc;
  color: rgb(23, 9, 9);
}
.p-complete {
  background-color: #42b442 !important;
  color: white !important;
}
.p-expire {
  background-color: #dc143c !important;
  color: white !important;
}
.p-past {
  background-color: #f8f8f8;
  color: rgb(23, 9, 9);
}
.p-mark {
  position: relative;
  bottom: 5%;
  width: 20px;
  left: 83%;
  cursor: pointer;
}
.payment-box1 {
  width: 150px;
  height: 150px;
  border-radius: 5px;
  padding: 5%;
}

//year dropdown
.react-datepicker__year-wrapper {
  max-width: 250px !important;
}
.react-datepicker__year .react-datepicker__year-text {
  margin: 5px !important;
}
.react-datepicker__year-text {
  font-size: 15px !important;
  font-weight: normal !important;
}
.react-datepicker__year-text--selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: #009ef7 !important;
}

.color-box {
  width: 15px;
  height: 15px;
}
.color-done {
  background-color: #42b442 !important;
}
.color-not-done {
  background-color: #dc143c !important;
}
.color-not-start {
  background-color: #dcdcdc;
}
